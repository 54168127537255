<template>
  <div class="px-5 py-4 card">
    <div class="mb-1 font-weight-bold">Đường dẫn</div>
    <tp-text-field
      class="mb-2"
      dense
      :disabled="Boolean(promotion.id)"
      hide-details
      outlined
      placeholder="/ten-khuyen-mai"
      :value="slug"
      @input="handleInputSlug"
    ></tp-text-field>

    <div
      v-if="!promotion.id"
      class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2 text-center"
    >
      Đường dẫn sẽ có hiệu lực sau khi tạo khuyến mại
    </div>

    <card-copy-slug
      v-if="promotion.id"
      class="mt-3"
      style="width: 50%"
      :slug="`/khuyen-mai${slug}`"
      :model-id="promotion.id"
    ></card-copy-slug>
  </div>
</template>

<script>
import { toKebabCase, removeVietnameseTones } from "@vt7/utils";
import CardCopySlug from "../../CardCopySlug.vue";

export default {
  components: {
    CardCopySlug
  },

  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    },

    slug() {
      return this.promotion.id
        ? `/${this.promotion.slug}`
        : toKebabCase(removeVietnameseTones(this.promotion.name));
    }
  },

  methods: {
    handleInputSlug(event) {
      this.promotion.slug = event;
    }
  }
};
</script>
