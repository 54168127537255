<template>
  <div class="px-5">
    <div class="d-flex flex-row align-center mb-5">
      <div class="font-weight-bold text-h5 mr-5">Tạo khuyến mại</div>

      <v-radio-group
        v-model="promotion.type"
        class="mt-0 pt-0"
        dense
        hide-details
        row
      >
        <v-radio :value="1" label="Theo sản phẩm"></v-radio>

        <v-radio :value="2" label="Theo hóa đơn"></v-radio>
      </v-radio-group>

      <v-spacer></v-spacer>

      <v-btn
        v-if="promotion.id && promotion.status === 3"
        :loading="promotionStatusRequest.value === 'loading-enablePromotion'"
        class="rounded-lg mr-4"
        color="primary"
        dark
        depressed
        @click="
          promotionStatusRequest.value === 'loading-enablePromotion'
            ? null
            : enablePromotion()
        "
      >
        Khởi động lại
      </v-btn>

      <v-btn
        v-if="promotion.id && promotion.status === 1"
        :loading="promotionStatusRequest.value === 'loading-disablePromotion'"
        class="rounded-lg mr-4"
        color="red accent-2"
        dark
        depressed
        @click="
          promotionStatusRequest.value === 'loading-disablePromotion'
            ? null
            : disablePromotion()
        "
      >
        Dừng
      </v-btn>

      <v-btn
        v-if="promotion.id"
        :loading="promotionStatusRequest.value === 'loading-updatePromotion'"
        class="rounded-lg"
        color="primary"
        depressed
        @click="
          promotionStatusRequest.value === 'loading-updatePromotion'
            ? null
            : updatePromotion()
        "
      >
        Lưu
      </v-btn>

      <v-btn
        v-else
        :loading="promotionStatusRequest.value === 'loading-createPromotion'"
        class="rounded-lg"
        color="primary"
        depressed
        @click="
          promotionStatusRequest.value === 'loading-createPromotion'
            ? null
            : createPromotion()
        "
      >
        Thêm
      </v-btn>
    </div>

    <v-form ref="promotionForm">
      <main-detail
        v-if="promotion.type === 1"
        @updateFormDataThumbnail="formDataThumbnail = $event"
      ></main-detail>

      <main-detail-by-order v-else></main-detail-by-order>
    </v-form>

    <modal-validate-promotion
      @onSubmit="promotion.id ? updatePromotion(true) : createPromotion(true)"
    ></modal-validate-promotion>
  </div>
</template>

<script>
import MainDetail from "./components/MainDetail/index";
import MainDetailByOrder from "./components/MainDetailByOrder/index";
import ModalValidatePromotion from "./components/ModalValidatePromotion.vue";
import { toFormData } from "@vt7/utils";
import MediaService from "@/core/service/media.service";

export default {
  components: {
    MainDetail,
    MainDetailByOrder,
    ModalValidatePromotion
  },

  data() {
    return {
      formDataThumbnail: null,
      isBackendValid: false
    };
  },

  created() {
    const promotionId = this.$route.params.promotionId;

    this.$store.dispatch("BRANCH/getAllBranches");
    this.$store.dispatch("LOCAL_ADMINISTRATION/getCities");
    this.$store.dispatch("CUSTOMER_GROUP/getAllCustomerGroups");
    this.$store.dispatch("PRODUCT_GROUP/getAllProductGroups");
    this.$store.dispatch("PROMOTION/getPromotionById", promotionId);
  },

  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    },
    promotionStatusRequest() {
      return this.$store.getters["PROMOTION/statusRequest"];
    },
    uploadedCover() {
      return this.$store.getters["PROMOTION/uploadedCover"];
    },
    uploadedThumbnail() {
      return this.$store.getters["PROMOTION/uploadedThumbnail"];
    },
    validateData() {
      return this.$store.getters["PROMOTION/validateData"];
    }
  },

  methods: {
    async createPromotion(isForce) {
      // Validate
      const promotionFormValid = await this.$refs.promotionForm.validate();
      if (!promotionFormValid) return;

      const objSender = JSON.parse(JSON.stringify(this.promotion));

      // Format time
      if (objSender.detail.type === 5) {
        const dateFrom = new Date(objSender.time_from);

        const dateTo = new Date(objSender.time_to);

        objSender.time_to = `${dateFrom.getFullYear()}-${(
          dateFrom.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${dateFrom
          .getDate()
          .toString()
          .padStart(2, "0")} ${dateTo.getHours()}:${dateTo.getMinutes()}:00`;
      }
      objSender.time_from = this.formatDateTime(objSender.time_from);
      if (objSender.time_to) {
        objSender.time_to = this.formatDateTime(objSender.time_to);
      }

      // Upload thumb image
      if (this.promotion.thumbnail instanceof File) {
        await this.uploadBannerImage(this.promotion.thumbnail);
        this.promotion.thumbnail = this.uploadedThumbnail;
        objSender.thumbnail = this.uploadedThumbnail;
      }

      // Upload cover image
      if (this.promotion.cover instanceof File) {
        await this.uploadCoverImage(this.promotion.cover);
        this.promotion.cover = this.uploadedCover;
        objSender.cover = this.uploadedCover;
      }

      // Upload frame
      if (this.promotion.frame instanceof File) {
        this.promotion.frame = objSender.frame = await this.uploadFrameImage(
          this.promotion.frame
        );
      }

      if (isForce) {
        objSender.force = true;
      }

      // Backend Validate
      if (!(await this.handleValidatePromotion(objSender))) return;

      // Request create
      await this.$store.dispatch("PROMOTION/createPromotion", objSender);

      console.log(
        "this.promotionStatusRequest.value",
        this.promotionStatusRequest
      );

      // Alert
      if (this.promotionStatusRequest.value === "success-createPromotion") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm khuyến mại thành công"
          }
        });
      } else if (
        this.promotionStatusRequest.value === "error-createPromotion"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">Lỗi thêm khuyến mại</span>`,
            message: this.promotionStatusRequest.message,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async disablePromotion() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Dừng khuyến mại</span><span class="font-weight-bold ml-1">"${this.promotion.name}"</span>`,
          message:
            "Khuyến mại sẽ bị dừng ngay bây giờ. Mọi thay đổi chưa được lưu sẽ bị mất. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Dừng",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "PROMOTION/disablePromotion",
                this.promotion.id
              );
              // Alert
              if (
                this.promotionStatusRequest.value === "success-disablePromotion"
              ) {
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã dừng khuyến mại"
                  }
                });
              }
            }
          }
        }
      });
    },

    async enablePromotion() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Khởi động khuyến mại</span><span class="font-weight-bold ml-1">"${this.promotion.name}"</span>`,
          message:
            "Khuyến mại sẽ được khởi động lại ngay bây giờ và không có thời gian kết thúc. Mọi thay đổi chưa được lưu sẽ bị mất. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "primary",
            text: "Khởi động",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "PROMOTION/enablePromotion",
                this.promotion.id
              );
              // Alert
              if (
                this.promotionStatusRequest.value === "success-enablePromotion"
              ) {
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã khởi động lại khuyến mại"
                  }
                });
              }
            }
          }
        }
      });
    },

    formatDateTime(date) {
      const dateTime = new Date(date),
        hours = dateTime
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = dateTime
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        day = dateTime
          .getDate()
          .toString()
          .padStart(2, "0"),
        month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${year}-${month}-${day} ${hours}:${mins}:00`;
    },

    async updatePromotion(isForce) {
      // Validate
      const promotionFormValid = await this.$refs.promotionForm.validate();
      if (!promotionFormValid) return;

      const objSender = JSON.parse(JSON.stringify(this.promotion));

      // Format time
      if (objSender.detail.type === 5) {
        const dateFrom = new Date(objSender.time_from);

        const dateTo = new Date(objSender.time_to);

        objSender.time_to = `${dateFrom.getFullYear()}-${(
          dateFrom.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${dateFrom
          .getDate()
          .toString()
          .padStart(2, "0")} ${dateTo.getHours()}:${dateTo.getMinutes()}:00`;
      }

      objSender.time_from = this.formatDateTime(objSender.time_from);
      if (objSender.time_to) {
        objSender.time_to = this.formatDateTime(objSender.time_to);
      }

      if (this.promotion.thumbnail instanceof File) {
        await this.uploadBannerImage(this.promotion.thumbnail);
        this.promotion.thumbnail = this.uploadedThumbnail;
        objSender.thumbnail = this.uploadedThumbnail;
      }

      // Upload cover image
      if (this.promotion.cover instanceof File) {
        await this.uploadCoverImage(this.promotion.cover);
        this.promotion.cover = this.uploadedCover;
        objSender.cover = this.uploadedCover;
      }

      // Upload frame
      if (this.promotion.frame instanceof File) {
        this.promotion.frame = objSender.frame = await this.uploadFrameImage(
          this.promotion.frame
        );
      }

      if (isForce) {
        objSender.force = true;
      }

      if (!(await this.handleValidatePromotion(objSender))) return;

      // Request update
      await this.$store.dispatch("PROMOTION/updatePromotion", objSender);

      // Alert
      if (this.promotionStatusRequest.value === "success-updatePromotion") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu thay đổi"
          }
        });
        await this.$store.dispatch("PROMOTION/getPromotionById", objSender.id);
      } else if (
        this.promotionStatusRequest.value === "error-updatePromotion"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">Lỗi cập nhật khuyến mại</span>`,
            message: this.promotionStatusRequest.message,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async uploadFrameImage(file) {
      const formData = toFormData({ file, folder: "core/banners" });
      const { data } = await MediaService.uploadImage(formData);
      return data;
    },

    async uploadBannerImage(file) {
      await this.$store.dispatch("PROMOTION/uploadThumbnail", file);
    },

    async uploadCoverImage(file) {
      await this.$store.dispatch("PROMOTION/uploadCover", file);
    },

    async handleValidatePromotion(payload) {
      await this.$store.dispatch("PROMOTION/validatePromotion", payload);

      if (
        this.validateData.errors.length ||
        this.validateData.warning_skus.length
      ) {
        this.$modal.show({ name: "modal-validate-promotion" });
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
