<template>
  <div>
    <v-radio-group
      v-model="promotion.detail.type"
      class="mt-0"
      dense
      hide-details="auto"
    >
      <v-radio :value="2" label="Giảm tiền"></v-radio>

      <v-radio
        v-if="promotion.type === 2"
        :value="3"
        label="Sản phẩm"
      ></v-radio>

      <v-radio
        v-if="promotion.type === 1"
        :value="3"
        label="Mua X khuyến mại Y"
      ></v-radio>

      <v-radio
        v-if="promotion.type === 1"
        :value="4"
        label="Tặng quà miễn phí"
      ></v-radio>

      <v-radio
        v-if="promotion.type === 1"
        :value="5"
        label="Flash sale"
      ></v-radio>

      <v-radio v-if="promotion.type === 1" :value="7" label="Combo"></v-radio>
    </v-radio-group>

    <div
      v-if="promotion.type === 2"
      class="amber lighten-5 text-body-1 px-3 py-2 mt-3"
    >
      Khuyến mại sản phẩm chỉ khả dụng khi áp dụng tất cả phương thức thanh toán
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  }
};
</script>
