<template>
  <div class="d-flex">
    <!-- Start: Customer Buy -->
    <div class="flex-1">
      <div class="font-weight-bold mb-2">Khách hàng mua</div>

      <template v-if="promotion.type === 1">
        <div
          class="text-body-2 font-weight-light grey--text text--darken-2 mb-2"
        >
          Khách hàng phải mua đủ số lượng sản phẩm lựa chọn để được nhận khuyến
          mại.
        </div>

        <button-add-skus
          :default-value="promotion.detail.apdung_type_detail.options"
          @onSelect="promotion.detail.apdung_type_detail.options = $event"
        ></button-add-skus>

        <list-selected-skus
          :selected-skus="promotion.detail.apdung_type_detail.options"
        ></list-selected-skus>

        <div v-if="!isGiftSection" class="mt-3">
          <div class="d-flex align-baseline">
            <div class="font-weight-medium mb-2">Số lượng</div>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-1"
                  color="grey"
                  dark
                  size="16px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>

              <div>
                Số lượng là 1 - khuyến mại <b>theo combo</b> (mua kèm deal sốc)
              </div>
              <div>
                Số lượng lớn hơn 1 - khuyến mại <b>mua nhiều tặng nhiều</b>
              </div>
            </v-tooltip>
          </div>

          <tp-text-field
            v-model.number="promotion.detail.apdung_type_detail.soluong"
            placeholder="Nhập số lượng"
            style="width: 160px;"
            hide-details="auto"
            type="number"
            validate="required|minVal:1"
          ></tp-text-field>
        </div>

        <div class="mt-2">
          <v-checkbox
            v-model="promotion.detail.apdung_type_detail.stock_check"
            class="mt-0"
            :false-value="0"
            :true-value="1"
            hide-details
            label="Áp dụng cho đến khi hết hàng trong kho."
          ></v-checkbox>

          <div class="amber lighten-5 text-body-1 px-3 py-2 mt-3 mb-5">
            Khuyến mại sẽ bị vô hiệu hóa khi tất cả các sản phẩm áp dụng hết
            hàng.
          </div>
        </div>
      </template>

      <template v-else>
        <div class="font-weight-medium mb-2">Tổng tiền hàng từ</div>

        <div style="width: 160px">
          <tp-input-price
            v-model="promotion.detail.apdung_type_detail.from_money"
            :empty-value="null"
            placeholder="Nhập giá trị"
            validate="required"
          ></tp-input-price>
        </div>
      </template>
    </div>
    <!-- End: Customer Buy -->
    <v-divider class="mx-5" vertical></v-divider>
    <!-- Start: Customer Receive -->
    <div class="flex-1">
      <div class="font-weight-bold mb-2">
        {{ isGiftSection ? "Khách hàng nhận quà miễn phí" : "Khách hàng nhận" }}
      </div>

      <div class="text-body-2 font-weight-light grey--text text--darken-2 mb-2">
        Khách hàng phải thêm số lượng mặt hàng bên dưới vào giỏ hàng của mình.
      </div>

      <div v-if="isGiftSection">
        <div class="mb-2">Quà tặng kèm khác hoặc mô tả</div>

        <vue-editor
          v-model="promotion.description_gift"
          class="mb-3"
          :editor-options="editorSettings"
          :editor-toolbar="customToolbar"
        ></vue-editor>
      </div>

      <button-add-skus
        :default-value="promotion.detail.tangkhach_type_detail.options"
        @onSelect="promotion.detail.tangkhach_type_detail.options = $event"
      ></button-add-skus>

      <list-selected-skus
        :selected-skus="promotion.detail.tangkhach_type_detail.options"
      ></list-selected-skus>

      <div class="mt-3" style="width: 160px">
        <div class="font-weight-medium mb-2">Số lượng</div>

        <tp-text-field
          v-model.number="promotion.detail.tangkhach_type_detail.soluong"
          placeholder="Nhập số lượng"
          hide-details="auto"
          type="number"
          validate="required|minVal:1"
        ></tp-text-field>
      </div>

      <div v-if="!isGiftSection" class="mt-5">
        <div class="font-weight-medium mb-2">Với giá trị giảm giá</div>

        <v-radio-group
          v-model="promotion.detail.tangkhach_type_detail.tang_type"
          class="mt-3"
          dense
          hide-details
        >
          <!-- <v-radio label="Miễn phí" :value="1"></v-radio> -->

          <div>
            <v-radio class="mb-0" label="Phần trăm" :value="2"></v-radio>

            <div
              v-if="promotion.detail.tangkhach_type_detail.tang_type === 2"
              style="width: 170px"
            >
              <tp-text-field
                v-model.number="
                  promotion.detail.tangkhach_type_detail.tang_value
                "
                class="ml-3"
                :disabled="
                  promotion.detail.tangkhach_type_detail.tang_type !== 2
                "
                hide-details="auto"
                placeholder="Giá trị"
                suffix="%"
                type="number"
                :validate="
                  promotion.detail.tangkhach_type_detail.tang_type === 2
                    ? 'required|minVal:0|maxVal:99'
                    : undefined
                "
              ></tp-text-field>
            </div>
          </div>

          <div class="mt-2">
            <v-radio class="mb-0" label="Số tiền" :value="3"></v-radio>

            <div style="width: 170px">
              <tp-input-price
                v-if="promotion.detail.tangkhach_type_detail.tang_type === 3"
                v-model="promotion.detail.tangkhach_type_detail.tang_value"
                custom-class="ml-3"
                :disabled="
                  promotion.detail.tangkhach_type_detail.tang_type !== 3
                "
                :empty-value="null"
                hide-details="auto"
                placeholder="Giá trị"
                :validate="
                  promotion.detail.tangkhach_type_detail.tang_type === 3
                    ? `required|maxVal:${minPriceOfReceiveSkus}`
                    : undefined
                "
              ></tp-input-price>
            </div>
          </div>
        </v-radio-group>
      </div>
    </div>
    <!-- End: Customer Receive -->
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import ButtonAddSkus from "./ButtonAddProduct/index.vue";
import ListSelectedSkus from "./ListSelectedSkus.vue";

export default {
  components: {
    ButtonAddSkus,
    ListSelectedSkus,
    VueEditor
  },

  props: {
    isGiftSection: {
      type: Boolean
    }
  },

  data() {
    return {
      customToolbar: [[{ list: "bullet" }], ["link"]],
      editorSettings: {
        modules: {
          clipboard: {
            matchers: [[Node.ELEMENT_NODE, this.customQuillClipboardMatcher]]
          }
        }
      }
    };
  },

  computed: {
    productGroups() {
      return this.$store.getters["PRODUCT_GROUP/allProductGroups"];
    },

    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    },

    minPriceOfReceiveSkus() {
      return Math.min(
        ...this.promotion.detail.tangkhach_type_detail.options.map(
          item => item.price
        )
      );
    }
  },

  methods: {
    customQuillClipboardMatcher(node, delta) {
      delta.ops = delta.ops.map(op => {
        return {
          insert: op.insert
        };
      });
      return delta;
    },

    onChangeApdungType() {
      this.promotion.detail.apdung_type_detail.ids = null;
    },

    onChangeTangkhachType() {
      this.promotion.detail.tangkhach_type_detail.ids = null;
    }
  },

  watch: {
    "promotion.detail.tangkhach_type_detail.tang_type"() {
      this.promotion.detail.tangkhach_type_detail.tang_value = null;
    }
  }
};
</script>
