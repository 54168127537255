<template>
  <v-menu v-model="isVisible" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" depressed outlined v-bind="attrs" v-on="on"
        ><v-icon left>mdi-pencil</v-icon>Nhập hàng loạt</v-btn
      >
    </template>

    <v-card>
      <div class="pa-4">
        <v-radio-group v-model="radioGroup" hide-details>
          <v-radio label="Phần trăm giảm" :value="0"></v-radio>

          <tp-text-field
            v-if="radioGroup === 0"
            v-model="percent"
            class="text-body-1 mt-0 pt-0 mb-2"
            dense
            flat
            hide-details="auto"
            outlined
            solo
            style="width:200px"
            type="number"
            :validate="radioGroup === 0 ? 'minVal:0|maxVal:99' : undefined"
          ></tp-text-field>

          <v-radio label="Giảm số tiền cố định" :value="1"></v-radio>

          <tp-input-price
            v-if="radioGroup === 1"
            v-model="price"
            custom-class="text-body-1 mt-0 pt-0"
            custom-style="width: 200px"
            dense
            flat
            hide-details
            outlined
            solo
          ></tp-input-price>
        </v-radio-group>

        <div class="mt-2">
          <div>Số lượng khuyến mãi</div>

          <v-text-field
            v-model="quantity"
            custom-class="text-body-1 mt-0 pt-0"
            dense
            flat
            hide-details
            outlined
            solo
            style="width:200px"
            type="number"
          ></v-text-field>
        </div>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="handleClosePopover">
          Hủy
        </v-btn>

        <v-btn color="primary" text @click="handleSubmit">
          Lưu
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: Array
    }
  },

  data() {
    return {
      isVisible: false,
      price: null,
      percent: null,
      quantity: null,
      radioGroup: 0
    };
  },

  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },

  methods: {
    handleClosePopover() {
      // Reset data
      this.price = null;
      this.percent = null;
      this.quantity = null;

      this.isVisible = false;
    },

    handleSubmit() {
      const updatedValues = this.value.map(item => {
        return {
          ...item,
          ...(this.quantity && { max_sold: Number(this.quantity) }),
          ...(this.price && {
            sale_price: item.price - Number(this.price)
          }),
          ...(this.percent && {
            sale_price:
              item.price -
              (item.price * Math.round(this.percent * 10)) / 10 / 100
          })
        };
      });

      const updatedData = this.promotion.detail.apdung_type_detail.options.map(
        item => {
          const match = updatedValues.find(val => val.id === item.id);
          return match ? match : item;
        }
      );

      this.promotion.detail.apdung_type_detail.options = updatedData;

      this.handleClosePopover();
    }
  }
};
</script>
