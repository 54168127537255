<template>
  <div class="d-flex">
    <div class="select-wrapper">
      <v-select
        v-model="selectedSearchTypeValue"
        class="text-body-1"
        dense
        hide-details
        :items="searchTypes"
        item-text="label"
        item-value="value"
        outlined
      ></v-select>
    </div>

    <div class="input-wrapper">
      <v-text-field
        v-model="searchText"
        class="text-body-1"
        clearable
        prepend-inner-icon="mdi-magnify"
        dense
        hide-details
        outlined
        :placeholder="dynamicPlaceholder()"
        @keydown.enter="getSearchSkus()"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isClearSearch: {
      type: Boolean
    }
  },

  data() {
    return {
      selectedSearchTypeValue: "SKU",
      searchText: "",
      searchTypes: [
        { value: "PRODUCT", label: "Tên sản phẩm" },
        { value: "SKU", label: "Tên SKU" },
        { value: "CATEGORY", label: "Category" },
        { value: "PRODUCT_GROUP", label: "Nhóm sản phẩm" }
      ]
    };
  },

  created() {
    this.$store.dispatch("PROMOTION/getSkusPromotion", {
      type: "SKU",
      search: ""
    });
  },

  computed: {
    skusPromotion() {
      return this.$store.getters["PROMOTION/skusPromotion"];
    }
  },

  methods: {
    dynamicPlaceholder() {
      const { label } = this.searchTypes.find(
        item => item.value === this.selectedSearchTypeValue
      );

      return `Tìm kiếm theo ${label}`;
    },

    getSearchSkus() {
      this.$emit("onSearch");

      const payload = {
        type: this.selectedSearchTypeValue,
        search: this.searchText
      };

      this.$store.dispatch("PROMOTION/getSkusPromotion", payload);
    }
  },

  watch: {
    searchText(value) {
      if (value === "" || value === null) {
        this.getSearchSkus();
      }
    }
  }
};
</script>

<style scoped>
.select-wrapper {
  margin-right: 8px;
  width: 180px;
}
.input-wrapper {
  width: 247px;
}
</style>
