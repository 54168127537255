<template>
  <v-card class="tuanle" flat>
    <card-overview></card-overview>

    <card-promotions-order
      v-if="false && promotion.type === 2"
      class="mt-5"
    ></card-promotions-order>

    <v-divider></v-divider>

    <card-slugs></card-slugs>
  </v-card>
</template>

<script>
import CardOverview from "@/modules/Promotion/pages/Detail/components/RightSidebar/components/CardOverview";
import CardPromotionsOrder from "@/modules/Promotion/pages/Detail/components/RightSidebar/components/CardPromotionsOrder";
import CardSlugs from "./components/CardSlugs.vue";

export default {
  components: { CardOverview, CardPromotionsOrder, CardSlugs },

  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  }
};
</script>
