<template>
  <v-card class="py-4" flat>
    <div class="text-h6 font-weight-bold mb-3 px-5">
      Chương trình khuyến mại theo hóa đơn đang chạy ({{
        filterPromotionsForOrder.length
      }})
    </div>

    <div>
      <v-hover
        v-for="(item, index) in filterPromotionsForOrder"
        :key="item.id"
        #default="{ hover }"
      >
        <router-link
          class="d-block black--text tp-btn--text px-5 py-2"
          :class="{
            'grey lighten-5': index % 2 === 0,
            'grey lighten-4': hover
          }"
          target="_blank"
          :to="{ name: 'promotion_detail', params: { promotionId: item.id } }"
        >
          <div
            class="text-body-1 font-weight-bold"
            :class="{ 'primary--text': hover }"
          >
            {{ item.name }}
          </div>
          <div v-if="item.note" class="text-body-2 mt-1">
            {{ item.note }}
          </div>
          <div class="text-body-2 grey--text text--darken-2 mt-1">
            <span>Từ {{ item.time_from }}</span>
            <span v-if="item.time_to">đến {{ item.time_to }}</span>
          </div>
        </router-link>
      </v-hover>
    </div>
  </v-card>
</template>

<script>
export default {
  computed: {
    filterPromotionsForOrder() {
      return this.promotionsForOrder.filter(
        item => item.id !== this.promotion.id
      );
    },

    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    },

    promotionsForOrder() {
      return this.$store.getters["PROMOTION/promotionsForOrder"];
    }
  },

  created() {
    this.$store.dispatch("PROMOTION/getPromotionsForOrder");
  }
};
</script>
