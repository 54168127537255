<template>
  <v-card class="pa-5" flat>
    <div class="mb-2 font-weight-bold">Tặng quà</div>

    <div class="d-flex">
      <!-- Start: Customer Buy -->
      <div class="flex-1">
        <div class="font-weight-bold mb-2">Khách hàng mua</div>

        <template v-if="promotion.type === 1">
          <div
            class="text-body-2 font-weight-light grey--text text--darken-2 mb-2"
          >
            Khách hàng phải mua đủ số lượng sản phẩm lựa chọn để được nhận
            khuyến mại.
          </div>

          <button-add-skus
            :default-value="promotion.detail.apdung_type_detail.options"
            @onSelect="promotion.detail.apdung_type_detail.options = $event"
          ></button-add-skus>

          <list-selected-skus
            :selected-skus="promotion.detail.apdung_type_detail.options"
          ></list-selected-skus>

          <div class="mt-3">
            <div class="d-flex align-baseline">
              <div class="font-weight-medium mb-2">Số lượng</div>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    color="grey"
                    dark
                    size="16px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>

                <div>
                  Số lượng là 1 - khuyến mại <b>theo combo</b> (mua kèm deal
                  sốc)
                </div>
                <div>
                  Số lượng lớn hơn 1 - khuyến mại <b>mua nhiều tặng nhiều</b>
                </div>
              </v-tooltip>
            </div>

            <tp-text-field
              v-model.number="promotion.detail.apdung_type_detail.soluong"
              disabled
              hide-details="auto"
              placeholder="Nhập số lượng"
              style="width: 160px;"
              type="number"
              validate="required|minVal:1"
            ></tp-text-field>
          </div>

          <div class="mt-2">
            <v-checkbox
              v-model="promotion.detail.apdung_type_detail.stock_check"
              :false-value="0"
              :true-value="1"
              class="mt-0"
              hide-details
              label="Áp dụng cho đến khi hết hàng trong kho."
            ></v-checkbox>

            <div class="amber lighten-5 text-body-1 px-3 py-2 mt-3 mb-5">
              Khuyến mại sẽ bị vô hiệu hóa khi tất cả các sản phẩm áp dụng hết
              hàng.
            </div>
          </div>
        </template>

        <template v-else>
          <div class="font-weight-medium mb-2">Tổng tiền hàng từ</div>

          <div style="width: 160px">
            <tp-input-price
              v-model="promotion.detail.apdung_type_detail.from_money"
              :empty-value="null"
              placeholder="Nhập giá trị"
              validate="required"
            ></tp-input-price>
          </div>
        </template>
      </div>
      <!-- End: Customer Buy -->
      <v-divider class="mx-5" vertical></v-divider>
      <!-- Start: Customer Receive -->
      <div class="flex-1">
        <div class="font-weight-bold mb-2">
          Khách hàng nhận
        </div>

        <div
          class="text-body-2 font-weight-light grey--text text--darken-2 mb-2"
        >
          Áp dụng khi khách hàng mua tất cả sản phẩm bên trái.
        </div>

        <div class="mt-5">
          <div class="font-weight-medium mb-2">Giá trị giảm giá</div>

          <v-radio-group
            v-model="promotion.detail.tangkhach_type_detail.tang_type"
            class="mt-3"
            dense
            hide-details
          >
            <!-- <v-radio label="Miễn phí" :value="1"></v-radio> -->

            <div>
              <v-radio :value="2" class="mb-0" label="Phần trăm"></v-radio>

              <div
                v-if="promotion.detail.tangkhach_type_detail.tang_type === 2"
                style="width: 170px"
              >
                <tp-text-field
                  v-model.number="
                    promotion.detail.tangkhach_type_detail.tang_value
                  "
                  :disabled="
                    promotion.detail.tangkhach_type_detail.tang_type !== 2
                  "
                  :validate="
                    promotion.detail.tangkhach_type_detail.tang_type === 2
                      ? 'required|minVal:0|maxVal:99'
                      : undefined
                  "
                  class="ml-3"
                  hide-details="auto"
                  placeholder="Giá trị"
                  suffix="%"
                  type="number"
                ></tp-text-field>
              </div>
            </div>

            <div class="mt-2">
              <v-radio :value="3" class="mb-0" label="Số tiền"></v-radio>

              <div style="width: 170px">
                <tp-input-price
                  v-if="promotion.detail.tangkhach_type_detail.tang_type === 3"
                  v-model="promotion.detail.tangkhach_type_detail.tang_value"
                  :disabled="
                    promotion.detail.tangkhach_type_detail.tang_type !== 3
                  "
                  :empty-value="null"
                  :validate="
                    promotion.detail.tangkhach_type_detail.tang_type === 3
                      ? `required|maxVal:${minPriceOfReceiveSkus}`
                      : undefined
                  "
                  custom-class="ml-3"
                  hide-details="auto"
                  placeholder="Giá trị"
                ></tp-input-price>
              </div>
            </div>
          </v-radio-group>
        </div>
      </div>
      <!-- End: Customer Receive -->
    </div>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import ButtonAddSkus from "./ButtonAddProduct/index.vue";
import ListSelectedSkus from "./ListSelectedSkus.vue";

export default defineComponent({
  components: { ListSelectedSkus, ButtonAddSkus },

  setup() {},

  watch: {
    "promotion.detail.apdung_type_detail.options": {
      handler(options) {
        this.promotion.detail.apdung_type_detail.soluong = options.length;
      },
      immediate: true,
      deep: true
    }
  },

  computed: {
    productGroups() {
      return this.$store.getters["PRODUCT_GROUP/allProductGroups"];
    },

    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    },

    minPriceOfReceiveSkus() {
      return Math.min(
        ...this.promotion.detail.tangkhach_type_detail.options.map(
          item => item.price
        )
      );
    }
  }
});
</script>
