<template>
  <v-card class="pa-5" flat>
    <div>
      <div class="font-weight-bold mb-2">Tiêu đề</div>
      <tp-text-field
        v-model="promotion.name"
        placeholder="Nhập tiêu đề"
        validate="required"
      ></tp-text-field>
    </div>

    <div v-if="promotion.type === 2">
      <div class="d-flex flex-row align-center mb-3">
        <div class="font-weight-bold">Áp dụng mã code</div>
        <v-switch
          v-model="promotion.detail.is_code"
          class="ml-3 pt-0 my-0"
          :false-value="0"
          :true-value="1"
          hide-details
        ></v-switch>
      </div>

      <tp-combobox
        v-model="promotion.detail.codes"
        deletable-chips
        :disabled="promotion.detail.is_code === 0"
        hide-selected
        multiple
        placeholder="Nhập mã code và enter"
        small-chips
        :validate="promotion.detail.is_code ? 'min:1' : undefined"
      ></tp-combobox>
    </div>
  </v-card>
</template>

<script>
import { toKebabCase, removeVietnameseTones } from "@vt7/utils";

export default {
  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },

  watch: {
    "promotion.name"(value) {
      if (!this.promotion.id) {
        this.promotion.slug = toKebabCase(removeVietnameseTones(value));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-generate {
  cursor: pointer;
  transition: all 0.4s ease;
}
</style>
