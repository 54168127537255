<template>
  <div>
    <!-- Start: Search box -->
    <div>
      <div class="font-weight-medium mt-4">
        Đã chọn ({{ selectedSkus ? selectedSkus.length : 0 }})
      </div>

      <div class="skus-list-wrapper">
        <v-list class="py-0">
          <v-list-item
            class="tp-border-thin rounded px-3 mt-2"
            v-for="(item, index) in selectedSkus"
            :key="index"
          >
            <v-list-item-content class="py-2">
              <v-list-item-title class="font-weight-medium">
                {{ item.name }}
              </v-list-item-title>

              <div class="grey--text text--darken-2 text-subtitle-2">
                <span class="font-weight-bold">{{ item.SKU }} - </span>
                <span class="item-subtitle" v-html="item.name"></span>
              </div>

              <div class="font-weight-bold text-subtitle-2">
                Giá bán {{ item.price | formatCurrency }}
              </div>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="removeSelectedProductOption(index)">
                <v-icon color="grey lighten-1">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/core/utils/debounce";

export default {
  props: {
    selectedSkus: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  computed: {
    filterSearchProductOptions() {
      if (this.selectedSkus) {
        const arr = this.selectedSkus.map(item => item.id);

        return this.promotion.detail.apdung_type_detail.from_money.filter(
          item => !arr.includes(item.id)
        );
      } else {
        return this.promotion.detail.apdung_type_detail.from_money;
      }
    },

    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    },

    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    }
  },

  created() {
    this.debounceGetSearchProductOption = debounce(
      this.getSearchProductOptions,
      700
    );
  },

  methods: {
    removeSelectedProductOption(index) {
      const arr = this.selectedSkus;

      arr.splice(index, 1);
      this.$emit("input", arr);
    },

    selectProductOption(item) {
      if (item && item.id) {
        const arr = this.selectedSkus ? this.selectedSkus : [];

        arr.unshift({ ...item });
        this.$emit("input", arr);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item-subtitle {
  ::v-deep p {
    display: inherit;
    margin-bottom: 0 !important;
  }
}
</style>
<style scoped>
.skus-list-wrapper {
  max-height: 286px;
  overflow: auto;
}
</style>
