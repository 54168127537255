<template>
  <div>
    <!-- Start: Value -->
    <div class="d-flex align-center">
      <div
        class="px-5 pt-5"
        v-if="promotion.type === 2 && promotion.detail.type === 1"
      >
        <div class="font-weight-bold mb-2">Tối đa</div>
        <div style="width: 160px">
          <tp-input-price
            v-model="promotion.detail.tangkhach_type_detail.max_value"
            class="text-body-1"
            :empty-value="null"
            placeholder="Nhập giá trị"
          ></tp-input-price>
        </div>
      </div>
    </div>
    <!-- End: Value -->

    <!-- Start: Applied products -->
    <div class="pa-5" v-if="promotion.type === 1">
      <div class="font-weight-bold mb-2">Sản phẩm áp dụng</div>

      <div class="mt-3">
        <card-product></card-product>

        <div class="amber lighten-5 text-body-1 px-3 py-2 mt-3">
          Khuyến mại sẽ bị vô hiệu hóa khi tất cả các sản phẩm áp dụng hết hàng.
        </div>
      </div>
    </div>
    <!-- End: Applied products -->
    <!-- Start: Order total -->
    <div class="px-5 pt-5 pb-3" v-else>
      <div class="font-weight-bold mb-2">Tổng tiền hàng từ</div>
      <div style="width: 160px">
        <tp-input-price
          v-model="promotion.detail.apdung_type_detail.from_money"
          :empty-value="null"
          placeholder="Nhập giá trị"
          validate="required"
        ></tp-input-price>
      </div>
    </div>
    <!-- End: Order total -->
  </div>
</template>

<script>
import CardProduct from "./CardProduct/index.vue";

export default {
  components: {
    CardProduct
  },
  data() {
    return {
      selectedProducts: []
    };
  },
  computed: {
    productGroups() {
      return this.$store.getters["PRODUCT_GROUP/allProductGroups"];
    },
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  }
};
</script>
