var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"d-flex flex-row align-center mb-5"},[_c('div',{staticClass:"font-weight-bold text-h5 mr-5"},[_vm._v("Tạo khuyến mại")]),_c('v-radio-group',{staticClass:"mt-0 pt-0",attrs:{"dense":"","hide-details":"","row":""},model:{value:(_vm.promotion.type),callback:function ($$v) {_vm.$set(_vm.promotion, "type", $$v)},expression:"promotion.type"}},[_c('v-radio',{attrs:{"value":1,"label":"Theo sản phẩm"}}),_c('v-radio',{attrs:{"value":2,"label":"Theo hóa đơn"}})],1),_c('v-spacer'),(_vm.promotion.id && _vm.promotion.status === 3)?_c('v-btn',{staticClass:"rounded-lg mr-4",attrs:{"loading":_vm.promotionStatusRequest.value === 'loading-enablePromotion',"color":"primary","dark":"","depressed":""},on:{"click":function($event){_vm.promotionStatusRequest.value === 'loading-enablePromotion'
          ? null
          : _vm.enablePromotion()}}},[_vm._v(" Khởi động lại ")]):_vm._e(),(_vm.promotion.id && _vm.promotion.status === 1)?_c('v-btn',{staticClass:"rounded-lg mr-4",attrs:{"loading":_vm.promotionStatusRequest.value === 'loading-disablePromotion',"color":"red accent-2","dark":"","depressed":""},on:{"click":function($event){_vm.promotionStatusRequest.value === 'loading-disablePromotion'
          ? null
          : _vm.disablePromotion()}}},[_vm._v(" Dừng ")]):_vm._e(),(_vm.promotion.id)?_c('v-btn',{staticClass:"rounded-lg",attrs:{"loading":_vm.promotionStatusRequest.value === 'loading-updatePromotion',"color":"primary","depressed":""},on:{"click":function($event){_vm.promotionStatusRequest.value === 'loading-updatePromotion'
          ? null
          : _vm.updatePromotion()}}},[_vm._v(" Lưu ")]):_c('v-btn',{staticClass:"rounded-lg",attrs:{"loading":_vm.promotionStatusRequest.value === 'loading-createPromotion',"color":"primary","depressed":""},on:{"click":function($event){_vm.promotionStatusRequest.value === 'loading-createPromotion'
          ? null
          : _vm.createPromotion()}}},[_vm._v(" Thêm ")])],1),_c('v-form',{ref:"promotionForm"},[(_vm.promotion.type === 1)?_c('main-detail',{on:{"updateFormDataThumbnail":function($event){_vm.formDataThumbnail = $event}}}):_c('main-detail-by-order')],1),_c('modal-validate-promotion',{on:{"onSubmit":function($event){_vm.promotion.id ? _vm.updatePromotion(true) : _vm.createPromotion(true)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }