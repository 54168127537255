<template>
  <div>
    <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
      Kích thước ảnh khuyến nghị
      <span class="font-weight-bold">là 2400 x 640 px</span>
    </div>

    <tp-input-image
      :acceptedFileTypes="['image/*']"
      :maxFileSize="500 * 1024"
      :src.sync="promotion.cover"
      allowCompress
      allowFileSize
      allowFileTypeValidation
      class="flex-grow-1"
      forceType="jpeg"
    ></tp-input-image>
  </div>
</template>

<script>
export default {
  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  }
};
</script>
