<template>
  <tp-modal name="modal-validate-promotion" width="860px">
    <v-card>
      <v-card-title class="font-weight-bold">
        Có lỗi xảy ra
      </v-card-title>

      <div class="modal-body px-5 py-3">
        <div v-if="validateData.errors.length" class="error-section">
          <div
            v-for="message in validateData.errors"
            :key="message"
            class="red--text font-weight-bold text-h6"
          >
            - {{ message }}
          </div>

          <v-divider class="my-3"></v-divider>
        </div>

        <div
          v-if="validateData.warning_skus.length && !validateData.errors.length"
        >
          <div class="text-h6 font-weight-bold">
            Có {{ validateData.warning_skus.length }} sku đang nằm trong khuyến
            mại khác
          </div>

          <div class="mb-3 text-body-1 font-weight-bold">
            Chọn để gỡ khỏi khuyến mại đang tạo
          </div>

          <div v-for="sku in validateData.warning_skus" :key="sku.id">
            <div class="d-flex justify-space-between align-center">
              <div>
                <div class="font-weight-bold">
                  {{ getSkuById(sku.id) ? getSkuById(sku.id).name : "NA" }}
                </div>

                <div v-html="sku.message"></div>
              </div>

              <div>
                <v-checkbox v-model="selectedSkus" :value="sku.id"></v-checkbox>
              </div>
            </div>

            <v-divider class="my-3"></v-divider>
          </div>
        </div>
      </div>

      <v-card-actions class="px-5 pb-4 pt-0">
        <v-spacer></v-spacer>

        <v-btn color="grey darken-1" text @click="closeModal">
          Thoát
        </v-btn>

        <v-btn
          v-if="!validateData.errors.length"
          color="primary"
          text
          @click="handleApply"
        >
          Áp dụng
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      selectedSkus: [],
      THINKPRO_CMS: process.env.VUE_APP_BASE_URL
    };
  },
  computed: {
    invalidSkus() {
      const skuIds = this.validateData?.warning_skus.map(sku => sku.id);

      const mergedSkus = this.uniqueData(
        [
          ...this.promotion.detail.apdung_type_detail.options,
          ...this.promotion.detail.tangkhach_type_detail.options
        ],
        "id"
      );

      const filteredSkus = mergedSkus.filter(sku => skuIds.includes(sku.id));

      return filteredSkus;
    },

    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    },

    validateData() {
      return this.$store.getters["PROMOTION/validateData"];
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-validate-promotion" });
    },

    uniqueData(array, key) {
      return array.filter(
        (obj, index, self) => index === self.findIndex(o => o[key] === obj[key])
      );
    },

    getSkuById(id) {
      const mergedSkus = this.uniqueData(
        [
          ...this.promotion.detail.apdung_type_detail.options,
          ...this.promotion.detail.tangkhach_type_detail.options
        ],
        "id"
      );

      const filteredSkus = mergedSkus.find(sku => sku.id === id);

      return filteredSkus;
    },

    handleApply() {
      this.handleRemoveSelectedSkus();
      this.$emit("onSubmit");
      this.closeModal();
    },

    handleRemoveSelectedSkus() {
      if (this.selectedSkus.length) {
        this.promotion.detail.apdung_type_detail.options = this.promotion.detail.apdung_type_detail.options.filter(
          sku => !this.selectedSkus.includes(sku.id)
        );

        this.promotion.detail.tangkhach_type_detail.options = this.promotion.detail.tangkhach_type_detail.options.filter(
          sku => !this.selectedSkus.includes(sku.id)
        );

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Gỡ thành công sku khỏi khuyến mại này"
          }
        });

        this.closeModal();
      }
    }
  }
};
</script>
