<template>
  <div>
    <div class="d-flex">
      <button-add-product
        class="mr-3"
        :default-value="promotion.detail.apdung_type_detail.options"
        @onSelect="handleAddSkus"
      ></button-add-product>

      <button-batch-fill
        v-if="selectedSkus.length > 1"
        v-model="selectedSkus"
      ></button-batch-fill>

      <v-btn
        v-if="selectedSkus.length > 1"
        class="ml-3"
        color="red"
        depressed
        outlined
        @click="handleDeleteSkus"
        ><v-icon left>mdi-delete</v-icon>Xóa hàng loạt</v-btn
      >
    </div>

    <div v-show="this.promotion.detail.apdung_type_detail.options.length">
      <div class="amber lighten-5 text-body-1 px-3 py-2 mt-3">
        Bỏ trống trường SL Khuyến mại nếu không muốn giới hạn
      </div>

      <table-product v-model="selectedSkus" class="mt-3"></table-product>
    </div>
  </div>
</template>

<script>
import { showToastActionAlert } from "@/core/composables";
import ButtonAddProduct from "../ButtonAddProduct/index.vue";
import ButtonBatchFill from "./components/ButtonBatchFill.vue";
import TableProduct from "./components/TableProduct.vue";

export default {
  components: {
    ButtonAddProduct,
    ButtonBatchFill,
    TableProduct
  },

  data() {
    return {
      selectedSkus: []
    };
  },

  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },

  methods: {
    handleAddSkus(value) {
      this.promotion.detail.apdung_type_detail.options = value;
    },

    handleDeleteSkus() {
      const selectedSkusId = this.selectedSkus.map(sku => sku.id);

      showToastActionAlert(this, {
        message: `Xóa thành công ${selectedSkusId.length} SKU khỏi khuyến mại `
      });

      this.promotion.detail.apdung_type_detail.options = this.promotion.detail.apdung_type_detail.options.filter(
        sku => !selectedSkusId.includes(sku.id)
      );

      this.selectedSkus = [];
    }
  }
};
</script>
