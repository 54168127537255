<template>
  <v-card class="pa-5" flat>
    <div>
      <div class="font-weight-bold mb-2">Tiêu đề</div>
      <tp-text-field
        v-model="promotion.name"
        placeholder="Nhập tiêu đề"
        validate="required"
      ></tp-text-field>
    </div>

    <div>
      <div class="d-flex flex-row align-center mb-3">
        <div class="font-weight-bold">Áp dụng mã code</div>
        <v-switch
          v-model="promotion.detail.is_code"
          :false-value="0"
          :true-value="1"
          class="ml-3 pt-0 my-0"
          hide-details
        ></v-switch>

        <v-spacer></v-spacer>

        <button
          v-if="promotion.detail.is_code"
          class="primary--text"
          type="button"
          @click="createAutoCode"
        >
          Tạo mã tự động
        </button>
      </div>

      <tp-combobox
        v-if="promotion.detail.is_code"
        v-model="promotion.detail.codes"
        deletable-chips
        hide-selected
        hint="Khách hàng phải nhập mã code này khi đặt hàng."
        multiple
        persistent-hint
        placeholder="Nhập mã code và enter"
        small-chips
      ></tp-combobox>

      <span v-else>
        Khi tắt áp dụng mã code, khuyến mại được tự động áp dụng với đơn hàng
        thỏa mãn. Khách hàng cũng sẽ thấy các khuyến mại này hiển thị trong danh
        sách voucher khi đặt hàng nếu đơn hàng của họ thỏa mãn điều kiện.
      </span>
    </div>
  </v-card>
</template>

<script>
import { toKebabCase, removeVietnameseTones, randomString } from "@vt7/utils";

export default {
  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },

  watch: {
    "promotion.name"(value) {
      if (!this.promotion.id) {
        this.promotion.slug = toKebabCase(removeVietnameseTones(value));
      }
    }
  },

  methods: {
    createAutoCode() {
      this.promotion.detail.codes ||= [];
      this.promotion.detail.codes.push(randomString(8));
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-generate {
  cursor: pointer;
  transition: all 0.4s ease;
}
</style>
