<template>
  <div>
    <v-card-title class="font-weight-bold px-5">Tổng quan</v-card-title>
    <div class="d-flex flex-row px-5 mb-5">
      <div class="flex-grow-1 mr-5">
        <div class="text-h6 font-weight-bold">
          {{ promotion.name ? promotion.name : "Chưa có tiêu đề" }}
        </div>
      </div>

      <div v-if="promotion.id" class="justify-end">
        <v-chip
          :color="
            promotion.status === 2
              ? 'blue lighten-1'
              : promotion.status === 1
              ? 'primary'
              : 'red accent-2'
          "
          class="font-weight-bold"
          outlined
          small
        >
          {{
            promotion.status === 2
              ? "Lên kế hoạch"
              : promotion.status === 1
              ? "Đang chạy"
              : "Đã hết hạn"
          }}
        </v-chip>
      </div>
    </div>

    <ul class="text-body-1 mx-3 pb-6" style="list-style: inherit">
      <!-- Start: Applied time -->
      <li class="mt-2">
        <div v-if="promotion.detail.type !== 5">
          Áp dụng từ
          <span class="font-weight-bold">
            {{ formatDateTime(promotion.time_from) }}
          </span>
          <span v-if="promotion.is_time_to && promotion.time_to">
            đến
            <span class="font-weight-bold">
              {{ formatDateTime(promotion.time_to) }}
            </span>
          </span>
        </div>

        <div v-if="promotion.detail.type === 5">
          Áp dụng trong khung giờ
          <span class="font-weight-bold">{{ formatFlashSaleTime }}</span>
        </div>
      </li>
      <!-- End: Applied time -->
    </ul>

    <v-divider></v-divider>

    <div class="mt-3 mb-4 px-5">
      <div class="mb-1 font-weight-bold">Giới hạn sử dụng</div>

      <v-checkbox
        v-model="promotion.is_limit_number_using"
        :disabled="promotion.type === 1"
        :false-value="0"
        :true-value="1"
        class="mt-1 mb-0"
        hide-details
        label="Giới hạn tổng số lượt sử dụng khuyến mại"
      ></v-checkbox>

      <tp-text-field
        v-if="promotion.is_limit_number_using"
        v-model="promotion.limit_number_using"
        class="ml-8 mt-2"
        hide-details="auto"
        outlined
        placeholder="Nhập số lượng"
        style="width: 140px"
        type="number"
      ></tp-text-field>

      <v-checkbox
        v-model="promotion.limit_for_user"
        :false-value="1"
        :true-value="0"
        class="mt-1 mb-0"
        hide-details
        label="Giới hạn một SĐT chỉ được sử dụng khuyến mại một lần"
      ></v-checkbox>
    </div>

    <template v-if="promotion.id">
      <v-divider></v-divider>

      <div>
        <div class="font-weight-bold my-3 px-5">
          Tính hiệu quả ({{ promotion.usingTimes }} lượt sử dụng)
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      isUnlimited: false
    };
  },

  watch: {
    "promotion.is_limit_number_using"(value) {
      if (!value) {
        this.promotion.limit_number_using = null;
      }
    }
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    customerGroups() {
      return this.$store.getters["CUSTOMER_GROUP/allCustomerGroups"];
    },

    formatFlashSaleTime() {
      return `${moment(this.promotion.time_from).format("HH:mm")} - ${moment(
        this.promotion.time_to
      ).format("HH:mm")} ngày ${moment(this.promotion.time_from).format(
        "DD/MM/yyyy"
      )}`;
    },

    isDisabledCheckboxUsingTime() {
      return (
        this.promotion.detail.type === 2 || this.promotion.detail.type === 5
      );
    },

    productGroups() {
      return this.$store.getters["PRODUCT_GROUP/allProductGroups"];
    },

    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },

  methods: {
    formatDateTime(date) {
      const dateTime = new Date(date),
        hours = dateTime
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = dateTime
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        day = dateTime
          .getDate()
          .toString()
          .padStart(2, "0"),
        month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${hours}:${mins} ${day}/${month}/${year}`;
    },

    getBranchName(id) {
      const res = this.branches.find(item => item.id === id);

      return res ? res.name : null;
    },
    getCustomerGroupName(id) {
      const res = this.customerGroups.find(item => item.id === id);

      return res ? res.name : null;
    },
    getProductGroupName(id) {
      const res = this.productGroups.find(item => item.id === id);

      return res ? res.name : null;
    }
  }
});
</script>
