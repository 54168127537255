<template>
  <v-card class="pa-5" flat>
    <div class="mb-2 font-weight-bold">Tặng quà</div>

    <custom-buy-x-get-y isGiftSection></custom-buy-x-get-y>
  </v-card>
</template>

<script>
import CustomBuyXGetY from "./CustomBuyXGetY.vue";
export default {
  components: {
    CustomBuyXGetY
  }
};
</script>

<style></style>
